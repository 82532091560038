import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Hero, Main as MainSection, Partners, Contact } from './components';
import Typography from '@mui/material/Typography';


const PortfolioMasonry = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container maxWidth={0.88}>
        <Hero />
      </Container>
    </Main>
  );
};

export default PortfolioMasonry;
