import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';

const mock = [
  {
    avatar: 'https://i.imgur.com/77LB5Xi.png',
    name:  <a href="https://www.google.com/maps/dir//National+Public+School,+Yeshwanthpur+Yeshwanthpur+Industrial+Area,+Phase+1,+Yeswanthpur+Bengaluru,+Karnataka+560022/@13.0310812,77.5410349,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae3d7baaef26b9:0xabd0ee0ee4462c6f!2m2!1d77.5410349!2d13.0310812">#9/1, Pipeline Road, Raghavendra Layout, (Behind RNS Motors), Yeshwanthpur, Bangalore-560 022</a>,
    isVerified: true,
  },
  {
    avatar: 'https://i.imgur.com/RVH4VjZ.png',
    name: <>
    <a href="tel:+91 80 23571220">Contact:+91 80 23571220</a> <br></br>
    <a href="https://wa.me/6364071122">Whatsapp:+91 6364071122</a><br></br> 
    </>,
    isVerified: false,
    
    
    href: '#',
  },
  {
    avatar: 'https://i.imgur.com/2Nbjuyv.png',
    name:  <>
    <a href="mailto:info@npsypr.edu.in">info@npsypr.edu.in</a>
    <br></br>
    <a href="mailto:admissions@npsypr.edu.in">admissions@npsypr.edu.in</a>
    <br></br>
    <a href="mailto:accounts@npsypr.edu.in">accounts@npsypr.edu.in</a>
    </>,
    isVerified: false,
   
  
    href: '#',
  },
];

const UserCardGrid = () => {
  const theme = useTheme();
  return (
    <Box bgcolor={'alternate.main'}>

      <Container>
      <Typography
              variant={'h4'}
              gutterBottom
              align={'center'}
              fontWeight={700}
              // sx={{ fontWeight: 700, color: 'common.white' }}
            >
              Contact Us
            </Typography>
            <br/>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
             
            > 
            Join us today to build a better future for your ward.<br/><br/>
            </Typography>
      
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid key={i} item xs={12} sm={4}>
              <Card
                sx={{
                  p: { xs: 2, md: 4 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 1,
                  height: 1,
                  background: 'transparent',
                  backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 75%, ${theme.palette.primary.main} 0%)`,
                }}
              >
                <Avatar
                  src={item.avatar}
                  variant={'circular'}
                  sx={{
                    width: 65,
                    height: 65,
                  }}
                />
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginTop={2}
                >
                  <Typography fontWeight={700}>{item.name}</Typography>
                 
                </Box>
                <Typography color={'text.secondary'}>{item.title}</Typography>
                <Box flexGrow={1} />
             
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default UserCardGrid;