import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';

const mock = [
  {
    title: 'Location',
    subtitle:
      <a href="https://www.google.com/maps/dir//National+Public+School,+Yeshwanthpur+Yeshwanthpur+Industrial+Area,+Phase+1,+Yeswanthpur+Bengaluru,+Karnataka+560022/@13.0310812,77.5410349,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae3d7baaef26b9:0xabd0ee0ee4462c6f!2m2!1d77.5410349!2d13.0310812">#9/1, Pipeline Road, Raghavendra Layout,<br/> (Behind RNS Motors), Yeshwanthpur,<br/> Bangalore-560 022</a>,
    icon: 'https://i.imgur.com/77LB5Xi.png',
  },
  {
    title: 'Contacts',
    subtitle:
    <>
    <a href="tel:+91 80 23571220">Contact:+91 80 23571220</a> <br></br>
    <a href="https://wa.me/6364071122">Whatsapp:+91 6364071122</a><br></br> 
    </>,
    icon: 'https://i.imgur.com/RVH4VjZ.png',
  },
  {
    title: 'E-mail us',
    subtitle:
    <>
    <a href="mailto:info@npsypr.edu.in">info@npsypr.edu.in</a>
    <br></br>
    <a href="mailto:admissions@npsypr.edu.in">admissions@npsypr.edu.in</a>
    <br></br>
    <a href="mailto:accounts@npsypr.edu.in">accounts@npsypr.edu.in</a>
    </>,
    icon: 'https://i.imgur.com/2Nbjuyv.png',
  },
];

const Contact = () => {
  return (
    <Box bgcolor={'#7EC8E3'}>
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant={'h4'}
              gutterBottom
              align={'center'}
              sx={{ fontWeight: 700, color: 'common.white' }}
            >
              Contact Us
            </Typography>
            <br/>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
              sx={{ color: 'common.white' }}
            > 
            Join us today to build a better future for your ward.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={Avatar}
                    width={{ xs: 60, md: 80 }}
                    height={{ xs: 60, md: 80 }}
                    marginBottom={2}
                    src={item.icon}
                  />
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    align={'center'}
                    sx={{ fontWeight: 600, color: 'common.white' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography sx={{ color: 'common.white' }} align={'left'}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;